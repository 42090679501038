


























































































































































































































































































































































































































































































import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator';
// @ts-ignore
import { CIQ } from 'chartiq/js/componentUI';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { getComponent } from '@/utils/helpers';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import setpointModule from '@/store/modules/setpointModule';
import { getCustomConfig } from './resources';
// ChartIQ library resources
let globalStx =  CIQ.ChartEngine
@Component({
  components: {
    Dropdown: () => getComponent('common/Dropdown'),
  },
})
export default class AdvancedChartComponent extends Vue {
	@Prop() config: any

	@Prop({ type: Function, default: ({}) => {} }) chartInitialized!: Function

	@Ref('container') container!: HTMLElement

	selectedSignals: any = []

	avaialbleDesription: any = [];

  availableSignalData: any = [];

	stx: CIQ.ChartEngine | undefined

		dropdownLocation: any[] = []

		hideDropdown() {
		  // console.log(this.selectedSignals);

			 for (const s in this.stx.chart.series) {
		    const series = this.stx.chart.series[s];
		    this.stx.removeSeries(series);
		  }
		  this.dropdownLocation = [];
		  const activeSeries = this.selectedSignals.map((s) => s.value);
		  if (activeSeries) {
		  this.addCustomSeries(this.selectedSignals.map((s) => s.value));
		  }
		}

		didSelectDropdownOption() {
		  // console.log(this.selectedSignals);

		//   this.dropdownLocation = [];
		}

		didSelectDropdown(e) {
		// if (type == 'Status Changes' || type == 'Duplicate Tasq') {
		  console.log(e);
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
		  this.dropdownLocation = [rect.x - 80, rect.y];

		  // } else {
		  // 	const rect = e.srcElement.getBoundingClientRect()
		  // 	this.dropdownLocation = [rect.x - 13, rect.y + 60]
		  // }
		}

		get avaialbleDataPointNames() {
		  return this.availableSignalData.map((dataPoint) => ({ value: dataPoint.name, text: dataPoint.name, type: dataPoint.type }));
		}

		addCustomSeries(series) {
	  const colors = ['red', 'green', 'blue', 'orange', 'purple', 'yellow', 'pink', 'skin', 'brown'];
	  const axis = new CIQ.ChartEngine.YAxis({ position: 'right' });
	  this.stx.chart.legend = {
	    x: 300,
	    y: 20,
	  };

		  const visibleSeries = this.availableSignalData.filter((dataPoint) => series.includes(dataPoint.name));

		  if (visibleSeries) {
		    visibleSeries.forEach((dataPoint, i) => {
		      this.stx.addSeries(dataPoint.name, {
	        color: colors[i],
	        data: dataPoint.data,
	        isComparison: true,
	        yAxis: axis,
	 renderer: 'Lines',
	        type: 'lines',
	        marginTop: 100, // give room for the legend
	      });
		    });
		  }
		}

		prepareSetpointData() {
		  if (setpointModule.adminSetpointData) {
		     this.availableSignalData = this.availableSignalData.concat(setpointModule.adminSetpointData);
		  }
		}

		async prepareSignalProdData() {
		  const tasqAvailableSignals = tasqSignalsModule.currentSignals;
	  if (tasqAvailableSignals) {
	    tasqAvailableSignals.forEach((currentSignal, i) => {
	     const dataPoint = currentSignal.dataset.map((d, i) => ({
	        Date: currentSignal.time[i],
	        Open: d,
	        Arrival: d,
	        Close: d,
	        Low: d,
	        High: d,
	        Volume: d,
		      }));

		      this.availableSignalData.push({
		        name: currentSignal.name,
		        data: dataPoint,
		        yxis: 'left',
		        type: 'signal',
		      });
	    });
	  }

      	    const availableProdData: any = tasqProductionDataChartModule.adminChartProductionData;
		    const availableProdDataPoints = availableProdData.dataset.filter((dataset) => dataset.data && dataset.data.length);

		    if (availableProdDataPoints) {
	    availableProdDataPoints.forEach((currentProdType, i) => {
	      const dataPoint = currentProdType.data.map((d, i) => ({
		          Date: new Date(availableProdData.date[i]).toISOString(),
	        Open: Number.parseFloat(d),
	        Arrival: Number.parseFloat(d),
	        Close: Number.parseFloat(d),
	        Low: Number.parseFloat(d),
	        High: Number.parseFloat(d),
	        Volume: Number.parseFloat(d),
		      }));

		      this.availableSignalData.push({
		        name: currentProdType.name,
		        data: dataPoint,
		        yxis: 'left',
		        type: 'production',
		      });
	    });
	  }
		}

		mounted() {
	  const config = this.config || getCustomConfig();

	  const { container } = this;
	  portalizeContextDialogs(container);
	  // Delay the call to createChartAndUI so any other AdvancedChart components on the page
	  // have a chance to call portalizeContextDialogs
	  window.setTimeout(() => {
	    const uiContext = this.createChartAndUI(config);
	    this.stx = uiContext.stx;
     globalStx = this.stx;
	    // console.log(this.stx.chart);
	    // console.log(container);
	    // console.log(config);

	    const axis = new CIQ.ChartEngine.YAxis({ position: 'left' });

		    let tasqAvailableSignals = tasqSignalsModule.currentSignals;

		 this.avaialbleDesription = tasqAvailableSignals.map((s, i) => ({
				 text: s.name,
				 value: s.name,
			 }));

	    this.stx.chart.legend = {
	      x: 300,
	      y: 20,
	    };
	  const colors = ['red', 'green', 'blue', 'orange', 'purple', 'yellow', 'pink'];
		    //   const axis = new CIQ.ChartEngine.YAxis({ position: 'left' });
	  this.stx.chart.legend = {
	    x: 300,
	    y: 20,
	  };
		    tasqAvailableSignals = tasqSignalsModule.currentSignals.filter((s) => s.name === 'Flowrate');
		    //   const tasqAvailableSignals = tasqSignalsModule.currentSignals.filter((s) => s.name !== 'Flowrate');
	  if (tasqAvailableSignals) {
	    tasqAvailableSignals.forEach((currentSignal, i) => {
	      const currentSignalData = currentSignal.dataset.map((d, i) => ({
	        Date: currentSignal.time[i],
	        Open: d,
	        Arrival: d,
	        Close: d,
	        Low: d,
	        High: d,
	        Volume: d,
	      }));

		        // console.log(currentSignalData);

	      // s one has data matching the range of the primary symbol
	      // and we are also appending new ticks to keep it in sync
	      this.stx.addSeries(currentSignal.name, {
	        color: colors[i],
	        data: currentSignalData,
	 renderer: 'Lines',
	        type: 'lines',
	        marginTop: 100, // give room for the legend
	      });
	    });
	  }

		    this.prepareSignalProdData();
		    this.prepareSetpointData();
		    //   this.selectedSignals.push({ value: 'Flowrate', text: 'Flowrate' });
        uiContext.stx.setPeriodicity({ period: 1, interval: 5, timeUnit: "week" });
		    // this.showMarkers();
	    this.chartInitialized({ chartEngine: uiContext.stx, uiContext, config });
	  }, 0);
		}

		beforeDestroy() {
		// Destroy the ChartEngine instance when unloading the component.
		// This will stop internal processes such as quotefeed polling.
		this.stx?.destroy();
		}

		createChartAndUI(config: any) {
	  // console.log(config);
	  const { container } = this;
	  const chart = new CIQ.UI.Chart();
	  const uiContext = chart.createChartAndUI({ container, config, layout: { crosshair: true, interval: 'week' } });
	  return uiContext;
		}

 showMarkers() {
  console.log('hi')
		  // Remove any existing markers

		  console.log(this.stx.masterData[this.stx.masterData.length - 1]);

		  const l = this.stx.masterData.length;
		  // An example of a data array to drive the marker creation
		  console.log(l);
		  const data = [
		   
		    {
		      x: this.stx.masterData[this.stx.masterData.length - 33].DT,
		      type: 'circle',
		      category: 'news',
		      headline: 'This is a Marker for a Another History Item',
		    },
		  ];
		  const story =		'Like all ChartIQ markers, the object itself is managed by the chart, so when you scroll the chart the object moves with you. It is also destroyed automatically for you when the symbol is changed.';

		  // Loop through the data and create markers
		  for (let i = 0; i < data.length; i++) {
		    const datum = data[i];
		    // datum.story = story;
		    const params = {
		      stx: this.stx,
		      xPositioner: 'date',
		      x:  data[i].x,
		      label: 'events',
		      node: new CIQ.Marker.Simple(datum),
		    };
		    const marker = new CIQ.Marker(params);
		    // console.log(marker);
		    marker.node.classList.toggle('highlight');
		  }
		  this.stx.draw();
		}
		
}

/**
 * For applications that have more then one chart, keep single dialog of the same type
 * and move it outside context node to be shared by all chart components
 */
function portalizeContextDialogs(container: HTMLElement) {
  container.querySelectorAll('cq-dialog').forEach((dialog) => {
    dialog.remove();
    if (!dialogPortalized(dialog)) {
      document.body.appendChild(dialog);
    }
  });
}
function dialogPortalized(el: Element) {
  if (!el.firstChild) {
    throw new Error('Element has no children');
  }
  const tag = el.firstChild.nodeName.toLowerCase();
  const result = Array.from(document.querySelectorAll(tag)).some(
    (el) => !el.closest('cq-context'),
  );
  return result;
}
